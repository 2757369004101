<template>
  <div class="table-scroll-wrapper">
    <v-data-table
      :headers="headers"
      :items="fields"
      dense
      hide-default-footer
      class="custom-table"
      :show-select="false"
      :disable-pagination="true"
      :disable-sort="true"
      :show-header="showHeader"
    >
      <template v-slot:item.boundary_image="{ item }">
        <img :src="item.boundary_image" style="width: 100px" />
      </template>

      <template v-slot:item.id="{ item }">
        {{ item.field_id }}
      </template>

      <template v-slot:item.name="{ item }">
        {{ item.field_name }}
      </template>

      <template v-slot:item.farm_name="{ item }">
        {{ item.farm_name }}
      </template>

      <template v-slot:item.client_name="{ item }">
        {{ item.client_name }}
      </template>

      <template v-slot:item.org_id="{ item }">
        {{ item.org_id }}
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip
          outlined
          small
          :color="`${
            item.status in BOUNDARY_STATUS_MAP
              ? BOUNDARY_STATUS_MAP[item.status]['color']
              : 'grey'
          }`"
        >
          {{
            item.status in BOUNDARY_STATUS_MAP
              ? BOUNDARY_STATUS_MAP[item.status]["text"]
              : item.status
          }}
        </v-chip>
      </template>

      <template v-slot:item.acres="{ item }">
        {{ item.acres }}
      </template>

      <template v-slot:item.intersect_ac="{ item }">
        {{ item.intersect_ac }}
      </template>

      <template v-slot:item.intersection_area="{ item }">
        {{ item.intersection_area }}
      </template>

      <!-- <template v-slot:item.crop="{ item }">
        <v-chip small outlined>
          {{ item.crop }}
        </v-chip>
      </template> -->

      <!-- <template v-slot:item.enrollment="{ item }">
        <v-chip small outlined>
          {{ item.enrollment }}
        </v-chip>
      </template> -->

      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="downloadBoundary(item)"
            >
              mdi-download
            </v-icon>
          </template>
          <span>Download Boundary</span>
        </v-tooltip>

        <v-tooltip v-if="duplicatedFields" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="archiveBoundary(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Archive Boundary</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { BOUNDARY_STATUS_MAP } from "@/constants/fields"

export default {
  name: "BoundaryDetailTable",
  props: {
    fields: {
      type: Array,
      required: true,
      default: () => [],
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    duplicatedFields: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      BOUNDARY_STATUS_MAP,
    }
  },
  computed: {
    headers() {
      return [
        { text: "", value: "boundary_image", width: "100px" },
        { text: "Field ID", value: "id", width: "120px" },
        { text: "Field Name", value: "name", width: "200px" },
        { text: "Farm Name", value: "farm_name", width: "200px" },
        { text: "Client Name (Grower)", value: "client_name", width: "200px" },
        { text: "Org ID", value: "org_id", width: "100px" },
        { text: "Status", value: "status", width: "120px" },
        { text: "Acres", value: "acres", width: "100px" },
        { text: "AC Overlap", value: "intersect_ac", width: "120px" },
        { text: "% Overlap", value: "intersection_area", width: "120px" },
        { text: "Actions", value: "actions", width: "100px", sortable: false },
      ]
    },
  },
  methods: {
    downloadBoundary(item) {
      this.$emit("download-boundary", item)
    },

    archiveBoundary(item) {
      this.$emit("archive-boundary", item)
    },
  },
}
</script>

<style scoped>
.table-scroll-wrapper {
  overflow-x: auto;
  max-width: 100%;
}

.custom-table >>> th {
  white-space: nowrap;
}

.custom-table >>> td {
  white-space: nowrap;
  font-size: 13px;
  padding: 8px 12px;
}

.custom-table >>> tbody > tr {
  margin-top: 8px;
  margin-bottom: 8px;
  display: table-row;
  border-bottom: 1px solid #f0f0f0;
}

.custom-table >>> tbody > tr:hover {
  background-color: #f9f9f9;
}

.custom-table >>> th:nth-child(1),
.custom-table >>> td:nth-child(1) {
  width: 120px;
}
.custom-table >>> th:nth-child(2),
.custom-table >>> td:nth-child(2) {
  width: 160px;
}
</style>
