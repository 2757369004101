<template>
  <b-sidebar
    id="boundary-detail-sidebar"
    width="90vw"
    :title="titleText"
    right
    shadow
    backdrop
    bg-variant="white"
    :visible="visible"
    @hidden="$emit('closeSidebar')"
  >
    <v-overlay :value="fetchingData" opacity="0.3">
      <v-progress-circular
        indeterminate
        size="30"
        color="primary"
      ></v-progress-circular>
      <div class="mt-6 text-bold">Fetching Data...</div>
    </v-overlay>
    <div class="px-4 pt-4">
      <!-- Primary Field Info -->
      <h5 class="font-weight-bold mb-2">Primary Field</h5>

      <BoundaryDetailTable
        :fields="[localField]"
        :show-header="true"
        :duplicatedFields="false"
        class="mt-4"
      />

      <v-divider class="my-4"></v-divider>

      <!-- Duplicates -->
      <div v-if="localIntersectedFields && localIntersectedFields.length">
        <h5 class="font-weight-bold mb-2">Duplicates / Overlaps</h5>

        <BoundaryDetailTable
          :fields="localIntersectedFields"
          :show-header="true"
          class="mt-4"
          @download-boundary="handleDownloadBoundary"
          @archive-boundary="handleArchiveBoundary"
        />
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import BoundaryDetailTable from "@/components/fields/BoundaryDetailTable.vue"
import FieldsAPI from "@/api/FieldsAPI"
export default {
  name: "BoundaryDetailSidebar",
  components: {
    BoundaryDetailTable,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    field: {
      type: Object,
    },
    intersectedFields: {
      type: Array,
      default: () => [],
    },
    overlapSearchType: {
      type: String,
    },
  },
  data() {
    return {
      fetchingData: false,
      localField: null,
      localIntersectedFields: [],
    }
  },
  computed: {
    titleText() {
      return this.field
        ? `Boundary Details: ${this.field.field_name}`
        : "Boundary Details"
    },
  },
  methods: {
    handleDownloadBoundary(item) {
      this.$emit("download-boundary", item)
      console.log("Need to implement download logic for boundary", item)
    },

    async handleArchiveBoundary(item) {
      this.fetchingData = true
      await FieldsAPI.archiveField(item.field_id).then(resp => {
        console.log("Archived Boundary: ", item.field_id)
      })
      const payload = {
        field_id: this.field.field_id,
        intersect_type: this.overlapSearchType,
      }
      await FieldsAPI.fetchBoundaryDetail(payload).then(resp => {
        this.localField = resp.data.source_field
        this.localIntersectedFields = resp.data.intersections
      })
      this.fetchingData = false
    },
  },
  watch: {
    field: {
      handler(newVal) {
        this.localField = newVal
      },
      immediate: true,
    },
    intersectedFields: {
      handler(newVal) {
        this.localIntersectedFields = newVal
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
::v-deep header.b-sidebar-header {
  padding: 32px 32px 0;
  position: relative;
}
::v-deep header.b-sidebar-header > button.close {
  font-size: 32px;
}
::v-deep header.b-sidebar-header > strong {
  width: 100%;
  text-align: left;
  font-size: 32px;
  color: #000000;
  margin-left: 12px;
}
.v-list-item {
  padding: 4px 0;
}
</style>
