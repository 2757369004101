export const OVERLAP = "overlap"
export const DATA_READY = "data ready"
export const DUPLICATE = "duplicate"

export const BOUNDARY_STATUS_MAP = {}
BOUNDARY_STATUS_MAP[OVERLAP] = {
  text: "Overlap",
  color: "orange",
}
BOUNDARY_STATUS_MAP[DATA_READY] = {
  text: "Data Ready",
  color: "green",
}
BOUNDARY_STATUS_MAP[DUPLICATE] = {
  text: "Duplicate",
  color: "red",
}
